<template>
	<div>
		<patrolStatistics ref="patrolStatistics" viewType="1" :tableHeader="tableHead"></patrolStatistics>
	</div>
</template>

<script>
export default {
	name: 'residentStatistics',
	components: {
		patrolStatistics: () => import('../patrolStatistics/index.vue'),
	},
	data() {
		return {
			tableHead: [
				{
					label: '驻派名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '驻派组名称',
					prop: 'medicalWorkerGroup',
					formatter: (row) => {
						return row.medicalWorkerGroup?.title || '-';
					},
				},
				{
					label: '驻派开始时间',
					prop: 'startTime',
					width: 160,
					formatter: (row) => {
						return row.startTime || '-';
					},
				},
				{
					label: '驻派结束时间',
					prop: 'endTime',
					width: 160,
					formatter: (row) => {
						return row.endTime || '-';
					},
				},

				{
					label: '驻派范围',
					prop: 'resourceAllocations',
					formatter: (row) => {
						if (!row?.resourceAllocations || row?.resourceAllocations.length === 0) return '-';
						let str = '';
						row.resourceAllocations.forEach((item) => {
							if (item.regions.length > 0) {
								let str1 = '';
								item.regions.forEach((itr1) => {
									str1 += itr1?.title || '';
								});
								str += str1 + ';';
							}
						});
						return str;
					},
				},
				{
					label: '配置资源',
					prop: 'seeMore',
				},
				{
					label: '驻派状态',
					prop: 'state',
					formatter: (row) => {
						return row.state == 'U' ? '未开始' : row.state == 'P' ? '进行中' : '已结束';
					},
				},
				{
					label: '所属账号',
					prop: 'account',
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped>
</style>